import * as Sentry from '@sentry/browser';

if (window.sentry) {
    const rejectErrorsWith = [
        'extractFilteredSchemaValuesFromMicroData',
        'Google 3P Authorization JavaScript Library'
    ];

    Sentry.init(
        {
            dsn: window.sentry.dsn,
            environment: window.sentry.environment,
            release: window.sentry.release,
            integrations: [
                Sentry.thirdPartyErrorFilterIntegration({
                    // Specify the application keys that you specified in the Sentry bundler plugin
                    filterKeys: ["cw-vite-mark"],

                    // Defines how to handle errors that contain third party stack frames.
                    // Possible values are:
                    // - 'drop-error-if-contains-third-party-frames'
                    // - 'drop-error-if-exclusively-contains-third-party-frames'
                    // - 'apply-tag-if-contains-third-party-frames'
                    // - 'apply-tag-if-exclusively-contains-third-party-frames'
                    behaviour: "apply-tag-if-contains-third-party-frames",
                }),
            ],
            denyUrls: [
                // External resources
                /www\.google-analytics\.com/i,
                /www\.googletagmanager\.com/i,
                /api\.leadinfo\.com/i,
                /collector\.leadinfo\.net/i,
                // Chrome extensions
                /^chrome:\/\//i,
                /^chrome-extension:\/\//i,
                // Local urls
                /^file:\/\//i,
                // webkit masked url
                /^\/\/hidden\//i
            ],
            beforeSend: function (event, hint){
                const error = hint.originalException;
                //@ts-ignore
                const message = error?.message || '';
                //@ts-ignore
                const stack = error?.stack || '';
                if (message.match(/dynamically imported module/)) {
                    event.fingerprint = ['error loading dynamically imported module'];
                }
                
                for (var i=0; i<rejectErrorsWith.length; i++) {
                    if (message.includes(rejectErrorsWith[i]) || stack.includes(rejectErrorsWith[i])) {
                        return null;
                    }
                }
                
                return event;
            }
        }
    );

    Sentry.getCurrentScope()
        .setLevel("error");
    Sentry.setTag('language', window.sentry.language)
    Sentry.setUser(window.sentry.user)
    
    Sentry.addBreadcrumb({
        category : 'page',
        message : 'Page loaded',
        level : "info"
    });

    window.addEventListener('vite:preloadError', (event) => {
       event.preventDefault();
       window.location.reload();
        // If anyone ever has a reload loop we need to remove this line. Otherwise this is the advised way to tackle this error (error loading dynamically imported module)
    });
}